:root {
    --title--enabled: #000000e0;
    --title--disabled: #00000077;

    --text--hint: #00000073;
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: #00000000;
}

body {
    background-color: #f5f5f5;
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
}


.width--full-size,
.width--full-size > .ant-space-item {
    width: 100%;
}


.text--left {
    text-align: left;
}

.text--center {
    text-align: center;
}

.text--hint {
    color: var(--text--hint);
}


.hint--block {
    display: block;
    color: var(--text--hint);
    font-size: 12px;
    margin-bottom: -2px;
    margin-top: 2px;
}


.layout__surtitle {
    margin: 0 !important;
    margin-bottom: 4px !important;
    padding: 16px 32px 0 16px;
    color: var(--title--disabled) !important;
    user-select: none;
}

.layout__title {
    margin-top: 0 !important;
    margin-bottom: 8px !important;
    padding: 16px 32px 0 16px;
    user-select: none;
}

.layout__content {
    padding: 16px;
    padding-top: 0;
}


.title-tabs {
    margin-top: 8px;
    padding-top: 0;
    padding-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
}

.title-tab {
    transition: color .15s ease-out;
    cursor: pointer;
}

.title-tab:not(:last-child) {
    margin-right: 18px;
}

.title-tab:active {
    cursor: grabbing;
}


.collapse-without-side-padding {
    .ant-collapse-header,
    .ant-collapse-content-box {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .ant-collapse-header {
        padding-top: 0 !important;
    }

    .ant-collapse-content-box {
        padding-bottom: 0 !important;
    }
}


.ant-card-head-title {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 32px;

    white-space: initial !important;
    text-overflow: initial !important;
}
